/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Live Indicator
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-live-indicator) {
  min-width: auto;
  min-height: auto;
}

:where(media-live-indicator [part='container']) {
  width: var(--media-live-indicator-width, 40px);
  height: var(--media-live-indicator-height, 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

:where(media-live-indicator [part='text']) {
  background-color: var(--media-live-indicator-bg, #8a8a8a);
  border-radius: var(--media-live-indicator-border-radius, 2px);
  color: var(--media-live-indicator-color, #161616);
  font-family: var(--media-font-family, sans-serif);
  font-size: var(--media-live-indicator-font-size, 12px);
  font-weight: var(--media-live-indicator-font-weight, 600);
  letter-spacing: var(--media-live-indicator-letter-spacing, 1.5px);
  padding: var(--media-live-indicator-padding, 1px 4px);
  transition: color 0.3s ease;
}

:where(media-live-indicator[data-live-edge] [part='text']) {
  background-color: var(--media-live-indicator-edge-bg, #dc2626);
  color: var(--media-live-indicator-edge-color, #f5f5f5);
}

:where(media-live-indicator[data-focus] [part='text']) {
  box-shadow: var(--media-focus-ring);
}

:where(media-live-indicator:not([data-live]) > shadow-root) {
  display: none;
}

:where(
    media-live-indicator:not([data-live]) [slot='live'],
    media-live-indicator[data-live-edge] [slot='live'],
    media-live-indicator[data-live] [slot='not-live'],
    media-live-indicator:not([data-live-edge]) [slot='live-edge']
  ) {
  display: none;
}

@media (pointer: fine) {
  :where(media-live-indicator[data-hover]) {
    background-color: unset;
  }
}
